<template>
  <!-- 线上订单 -->
  <div class="supplierManagement" v-loading="loading">
    <div class="globle_border content">
      <header>
        <el-select v-model="payStatus" placeholder="请选择缴费状态" clearable @change="onCurrentPage">
          <el-option v-for="item in payStatusArr" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker v-model="formInline.time" @change="gettime" type="daterange" range-separator="至"
          start-placeholder="结算开始日期" end-placeholder="结算结束日期"
          style="border-radius: 10px; background: #f2f2f2; margin-left: 20px; border: none"
          value-format="yyyy-MM-dd"></el-date-picker>
      </header>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData">
          <el-table-column label="序号" min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="orderNum" label="缴费单号" show-overflow-tooltip min-width="180" align="center">
          </el-table-column>
          <el-table-column prop="confirmTime" label="结算时间" show-overflow-tooltip min-width="130" align="center">
          </el-table-column>
          <el-table-column prop="" label="结算周期" show-overflow-tooltip min-width="150" align="center">
            <template slot-scope="scope">
              <div>{{ `${scope.row.startDate}-${scope.row.endDate}` }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="orderAmount" label="欠费总额(元)" show-overflow-tooltip min-width="110" align="center">
          </el-table-column>
          <el-table-column prop="" label="缴费情况" show-overflow-tooltip min-width="100" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.status == 1">已缴费</div>
              <div v-if="scope.row.status == 0">未缴费</div>
            </template>
          </el-table-column>
          <el-table-column label="缴费时间" min-width="200" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.payTime || '-' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="150" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button v-if="scope.row.status == 0" type="danger" size="small" plain
                @click="handlePay(scope.row)">缴费</el-button>
              <el-button type="primary" size="small" plain @click="handleCommand(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalCount"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      // 表格数据
      tableData: [],
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
      startTime: "", //开始时间
      endTime: "", //结束时间
      formInline: {
        time: [],
      }, //日期区间
      //缴费状态
      payStatusArr: [
        {
          value: "0",
          label: "未缴费",
        },
        {
          value: "1",
          label: "已缴费",
        },
      ],
      payStatus: "", //缴费状态
    }
  },
  created() {
    this.getRequest()
  },
  methods: {
    // 请求
    getRequest() {
      this.loading = true
      this.$axios
        .post(this.$api.selectSettlementArrearsPayList, {
          payStatus: this.payStatus || null,//状态：0未缴费，1已缴费
          shopName: null, //商超名称 
          shopId: this.$store.state.userInfo.shopId, //登录
          startDate: this.startTime || null, //开始时间
          endDate: this.endTime || null, //结束时间
          page: this.currentPage,
          pageSize: this.pageSize,
        })
        .then(res => {
          const { code, result } = res.data
          if (code == 100) {
            this.totalCount = result.totalCount
            this.tableData = result.list
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 时间区间搜索
    gettime() {
      this.startTime = this.formInline.time ? this.formInline.time[0] || "" : ""
      this.endTime = this.formInline.time ? this.formInline.time[1] || "" : ""
      this.onCurrentPage()
      // console.log('开始时间:', this.startTime, '结束时间:', this.endTime);
    },
    // 查看按钮
    handleCommand(row) {
      this.$router.push({ name: "arrearageDet", query: { id: row.id, startDate: row.startDate, endDate: row.endDate } })
    },
    // 缴费按钮
    handlePay(row) {
      //  缓存第三方支付的获取验证码参数和支付参数
      let query = {
        sourceId: row.id, // 对应平台的主键id
        fee: row.orderAmount, // 缴费金额
        shopId: this.$store.state.userInfo.shopId, //商超id
      }
      this.$store.commit('setPayQuery', query)
      this.$router.push({ name: "paymentPage" })

    },
    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest() //请求数据
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      this.tableData = []
      this.currentPage = val
      this.getRequest()
    },

    // 订单子状态文字颜色
    getOrderFormStatusColor(status) {
      switch (status) {
        case "1":
          return "red" //待付款
        case "2":
          return "red" //待接单
        case "8":
          return "red" //待退款
        default:
          return ""
      }
    },
    // 支付类型
    getOrderTypeText(status) {
      switch (String(status)) {
        case "0":
          return "现金"
        case "1":
          return "支付宝"
        case "2":
          return "微信"
        case "3":
          return "会员"
        case "4":
          return "银联"
        case "5":
          return "其他"
        default:
          return
      }
    },
  },
}
</script>

<style scoped lang="scss">
.supplierManagement {
  .content {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.5;
    }

    // 头部+搜索
    header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;

      /deep/.el-date-editor {
        width: 280px !important;
        margin-left: 10px !important;
        margin-bottom: 20px;
      }

      .el-input,
      .el-select {
        margin-left: 10px;
        width: 180px;
        margin-bottom: 20px;
      }
    }
  }

  .tableBox {
    overflow: auto;

    :deep .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      content: "";
      position: absolute;
      background-color: #ebeef5;
      opacity: 0;
    }

    :deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px;
      /*滚动条宽度*/
      height: 4px;
      /*滚动条高度*/
    }
  }

  // 操作下拉框内容
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .el-date-editor,
  .el-range-editor,
  .el-input__inner,
  .el-date-editor--daterange {
    margin-left: 0px !important;
  }

  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 250px;
  }
}
</style>
